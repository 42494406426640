<template>
  <div class="convention-hall page">
    <van-nav-bar class="nav-bar" :title="$t('寶衡传媒')">
      <template #right>
        <img
          src="../../assets/lang.png"
          style="width: 26px; height: 26px"
          @click="showPicker = true"
        />
        <!-- <van-icon name="arrow-left" color="#fff" @click="back()" /> -->
      </template></van-nav-bar
    >
    <div class="convention-item">
      <van-swipe
        :autoplay="3000"
        lazy-render
        class="my-swipe"
        :show-indicators="false"
      >
        <van-swipe-item v-for="image in images" :key="image"
          ><img :src="image" class="banner_img"
        /></van-swipe-item>
      </van-swipe>
      <div class="describe">
        <ul>
          <li>
            <div>{{ $t("寶衡传媒") }}</div>
            <div style="margin-top: 5px;width: 100%;display:block;font-size: 100%;">{{ $t("华人第一福利品牌") }}</div>
          </li>
          <li>
            {{ $t("为了会员权益，网站只展示") }}
            <b style="font-size: 16px; color: #f8c693">1%</b>
            {{
              $t(
                "资源并且不定期更新只为展示实力，如需查询预约更多资源请联系上级接待"
              )
            }}
          </li>
        </ul>
        <div>
          {{
            $t(
              "本平台所有资源真实丶安全丶可靠，全国空降丶同城约炮选取你心仪的她，与您零距离邂逅，快加入我们吧"
            )
          }}
        </div>
      </div>

      <van-tabs animated sticky line-width="100px" :swipeable="true">
        <van-tab :title="$t('寶衡资源')">
          <!-- <div class="card">本平台全网最真实外围+商务伴游+同城激情，为保证每个用户的个人隐私，客户仅限通过联系接待员或平台资深会员实名推荐才可加入。</div> -->
          <!-- <div class="address">
						<van-row type="flex" justify="" gutter="65" v-for="(val, key) in addlist" :key="key">
							<van-col span="" offset="" @click="addgo(v)" v-for="(v, k) in val" :key="k">{{ v.name }}</van-col>
						</van-row>
					</div> -->
          <div
            class="xuanfei"
            v-for="(val, key) in xuanfeilist"
            :key="key"
            @click="xfts(val.id)"
          >
            <div class="left-div">
              <div>ID:{{ val.itemid }}</div>
              <div>
                <ul>
                  <li>{{ $t("昵称") }}： {{ val.nickname }}</li>
                  <li>{{ $t("类型") }}：{{ $t(val.typelabel) }}</li>
                  <li>{{ $t("所在地区") }}：{{ $t(val.region) }}</li>
                  <li>{{ $t("服务项目: 激活可见") }}</li>
                </ul>
              </div>
              <div>
                <ul>
                  <li>
                    <img
                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAAA8CAYAAAA6/NlyAAAAAXNSR0IArs4c6QAABIRJREFUaEPtWl1oHFUU/s7Miq8aERFFihbRh7agVuhDsS2CL75opWClah+Umuw9s6EiRgsdENQHze7cu6tEReNPIqJGwRfR+gMqilIfbEQRxArqm4l9a6AzR27ZDZNkN/Ozm92S2QvzsnO+853vnjvn/i2hYI0KphdDwZs948MMDzO8yXpgOKQ3WULXyBlmeJjhnD1QrVavdRxnGxFtA2CfawBcEnus5/9izx8ATorIz67r/lIul//MSb0urKdDWmt9MREdFJGDAG7vMuATRDQrIrPMvNSlr2V4TwTX6/XLRKQMwIq9vlfBWT9E9FsURW+7rmvK5fK/3fruSrDv+6WRkREr1D7XdRtMAv53APWFhYW67/vn8nLlFhwEwc1E9BKAm/KS58T9CGCCmT/Jg88l2BizT0Q+y0PYK4yIPOR53itZ/WUWbIxRIqKzEm2Q/ZPM/HQW35kEB0HgE9HxLAQbbSsi93ue92ZantSCjTGPi8gzaR2vY2enmHkAlzfn5q5dEtEBpdS7aRylEmyMsdPNTBqHHWxeBfCViJzyPO9ky6ZWq13hOM4OEdlORI81OyEXTVrRiYKr1eoW13W/ALAlVyTAfmaeS8I2Go2tYRi+DGBPkm2790T0t13sKKV+XQ+fKFhr/RqAB3ME8Z0NPusqqZuiSEQfKKXuzi1Ya22FWsFZ2zlmvmg1qDl37xGRqxzHORtF0fzi4uKc7/tn47Za60cAvJCV1NqLyBOe53WsNR0zrLW+2n53OYfyEWaeagVsfdmpjIjuaiPiDIBjzFyPvwuC4D0i2p9HtOu6t46Njf3Qduh3cqi1fg7A0RyEU8x8pIWbnJzcWiqVPk5aehLRs0qpiRau0WjcGIbhNwAuzRoDEb2ulGr7GbbNcK1W2+k4zrcA3KxkpVJpx+jo6E+x7H4J4LY0fqIoOlSpVN5q2RpjZpo7rzTwFTYiss/zPFtsV7S2goMgmLHbvMwswBlmtnve8y0IggNE9E4GP/PMbPfO55vW+hiApzLgl01F5H3P8+5JFKy1tvvYT/OQAPiamXfHAn4DwKEsvohou1LqlMXU6/U7oyj6KAt+le0dqzcZazIcBMHDRLRccDKSvcjMozHBtnDcksWHLWxKqQ8tZmpq6sqlpaV/suDjtkTESimz4rfVzgonuHBDulls8s6BF1LRmvU8777EotUUvJeIPs/z7Vwg01IYRdGuSqWyZvHRcaVljJkWkQdyiB74wgPA88z8aLvYOwpuNBo7wzD8PodgCxnk0vI0gN3M/Fcmwc2hPUFEmY5QmiQD2zwAOMzM050Slbg9NMbMiUi7RX9S8vu+PQQwzcyH1wssjeAbAJywW7okhR3e9+UAAMDpMAz3jo+P2yHdsSUKtshCHfG0uqpQh3gx0cdFxM85tDcEtmHHtDHRxTmIj4kuzlVLS3ShLtNaogt1XRqvQPZCPAxD5TjOvZv6Qnx12S3MXx7azTeF+FPLhky0PXaaamnZY86BuhsKHmj394F8mOE+dPJAKYYZHmj394F8mOE+dPJAKQqX4f8BYPRTW5RGL5kAAAAASUVORK5CYII="
                      alt=""
                    />
                    <span>{{ val.views }}</span>
                  </li>
                  <li>
                    <img
                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAAA8CAYAAAA6/NlyAAAAAXNSR0IArs4c6QAABOBJREFUaEPtmmtoHFUUx///2SiKQdsKRqUoBfWD+kGREltRqlZBK6LQRLRaK4So+ZLt3DtJfEEQIjRzZ1O1vioSXwS0KC3EkrZY+sUg2hoQRNT6QFFo0agItWqyR2aSLNvsc3ZnNskm9+PuOed/fveeO3PPzBCLbHCR8WIJuN5XfGmFl1a4zmag4pIW110HYB3IJlCapubFOgCRw9T606jmSXp7T8c5Z90C4VoImyByPogTAL+AJd9BrC9p24fL1QsFLMZcAwvtENwF4LwiIkcg2Emtd5abyGw7GRw8A+PjfYBsAbCieBwOQeRNar2vlF7ZwJIyr0DQXirgrP+PAOleqq7hMH5B9VjsA7A2jB+A16n0Q8V8ygIWz7wLoCWkeJY5+6jUk+X4i+d2AwFsohz7PDY/UOlVhXxLAlcPOy1NvA9Y99O2/y6UjKTMOxC0Vgia7XaUSl+aL05R4MhgM8qyF0w8QNsez9mznhkE4O/XqMYuKp0zeQWBxXNfBvhwVOqZOMRBwNpM2/555jcx5gUQHZFrCZLU+tnsuHmBJZW6ApIeA3Ba5ElMBRwFrQdp20fFc12AOiad75GWa+k4x2fi5wf2XA+gHVMSM2HHABkG+FS8OuygUi8VBBbXXQWLnwFYFm8itYou71E5GwsDe+7T8c96rWADnd+pdObgklPS4plPAKyuaUpxi1mJ5dy69Q9fJh/wsRLHxrjTiz5+Q/oydnZ9kwMsO3aci39O/hq94lxH5HVUajQXeKD/KqQt/3ZUX0NwN7XenQvseXcCsqe+aH0aaadyXs0FNqYTxPa6AyaeoK2fyQVOuQ6E/fUHLPfQdvyO79SrtHj9WwDLP8TX10g0XMxk8sd8e/h2QD6oK1rBt9T6krwnLUmlVkDSv9UVMDhEpTblBQ6uZ54ZAnBv3UDPahFzT1rGbALxdt0Ac7KZdrd/XA5G/vbQmI9BNNcB9AEqfWs2R4F+uH8zYL2x8IHTHVRdmV644ApP7+WDAG5cwNDjSDRczmTSb4Yyo/AzrQGzEWnsWrjA8hqV0zY7/+JPLVNmGIINCxOaG6jU3nDArrsGFkcAnL2woLmNSvXky7n0g3hj2kAEncaCGMQhrLxoPVtbJysCDi5gxmwH0TnvgUVOgtbNM81+5cAtLQk0N4+AWD+/oUVROaliOZYs6Rlncd0r4b8fIvO+s5n7ieBzVKpkFZYNHJS2D21xP4AL5h7wlJvrIdq6rDNDKOAA+vltF+LfxFcAGucN9H8Ty9jT82c5+YQGDqBTqTMh6RPlCMRuQ6uZtp1pDkrpVQSc2dfG/QnkylIisf1Pa3WY7zv8PKoCnlptMwrBmtig8gc+jkTDDUwm/a0ValQNHEBX/UlEqJzHMDF5B7u7fwnlNW0cCXANoYcxMXkfu7v/qgQ2kpLOFhbPfRHgo5UmU9xPDJXjVBs7shXOXMgGTBsmpR/k8mqTm/Y/BoFDrd+KIl7kwEF5+x+wAf0gbqoqSXI/rLTDpPN5VXGynGMBDqB7ey00NvrQqrJkoynh2dqxAWdK3JjbQD4GyPXlgcsIYBkq9WF59uGsYgfOgHueD/14kSPp1wB90Fh775oBT926vKtBeSTrm03/XvoRKPuQ5h5qHfvL+JoChyu+eKyXgOOZ1/kTdWmF589axJPJolvh/wF8iXZMtgf/PgAAAABJRU5ErkJggg=="
                      alt=""
                    />
                    <span>{{ val.collection }}</span>
                  </li>
                </ul>
              </div>
            </div>
            <div class="right-div">
              <img :src="domain + val.flagimg" alt="" />
            </div>
            <i class="sign-i">{{ $t("已认证") }}</i>
          </div>
        </van-tab>
        <van-tab :title="$t('实时配对')">
          <div class="scroll-cmp">
            <van-swipe
              :height="52"
              vertical
              :autoplay="4000"
              :duration="1000"
              :show-indicators="false"
            >
              <van-swipe-item v-for="(val, key) in peiduilist" :key="key">
                <div>
                  <span>{{ val.name }}</span>
                  <span>{{ val.tel }}</span>
                </div>
                <div>
                  <span>{{ val.title }}</span>
                </div>
              </van-swipe-item>
            </van-swipe>
          </div>
        </van-tab>
        <van-tab :title="$t('约炮心得')">
          <div class="scroll-cmp">
            <van-swipe
              :height="52"
              vertical
              :autoplay="4000"
              :duration="1000"
              :show-indicators="false"
            >
              <van-swipe-item v-for="(val, key) in yuepaolist" :key="key">
                <div>
                  <span>{{ val.name }}</span>
                  <span>{{ val.tel }}</span>
                </div>
                <div>
                  <span>{{ val.title }}</span>
                </div>
              </van-swipe-item>
            </van-swipe>
          </div>
        </van-tab>
        <van-tab :title="$t('服务条款')">
          <div class="card">
            {{
              $t(
                "本平台全网最真实外围+商务伴游+同城激情，为保证每个用户的个人隐私，客户仅限通过联系接待员或平台资深会员实名推荐才可加入。"
              )
            }}
          </div>
          <div class="rig-box">
            <p class="rig-title">{{ $t("有哪些资源?") }}</p>
            <p class="rig-content">
              {{
                $t(
                  "网红、模特、空姐、嫩模、大学生，只有您想不到，没有本平台办不到"
                )
              }}
            </p>
            <p class="rig-title">{{ $t("服务范围") }}?</p>
            s
            <p class="rig-content">
              {{ $t("服务项目：做爱3次 可无套内射 深喉口交 漫游  毒龙 按摩") }}
            </p>
            <p class="rig-content">
              {{ $t("可拍照留念： 私处特写 做爱录屏 口交录屏") }}
            </p>
            <p class="rig-content">
              {{ $t("可指定制服：护士-女仆-空姐-秘书-女警-猫女") }}
            </p>
            <p class="rig-content">
              {{ $t("可捆绑轻虐：配合使用各式男女情趣性爱道具") }}
            </p>
          </div>
        </van-tab>
      </van-tabs>
    </div>
    <van-popup v-model="showPicker" round position="bottom">
      <van-picker
        show-toolbar
        :title="$t('标题')"
        :columns="columns"
        value-key="key"
        :confirm-button-text="$t('确定')"
        :cancel-button-text="$t('取消')"
        @cancel="showPicker = false"
        @confirm="onConfirm"
      />
    </van-popup>
  </div>
</template>

<script>
export default {
  data() {
    return {
      domain: this.serviceUrl,
      showPicker: false,
      columns: this.$langArray,
      addlist: [
        {
          0: this.$t("北京"),
          1: this.$t("上海"),
          2: this.$t("广州"),
          3: this.$t("深圳"),
        },
        {
          0: this.$t("南京"),
          1: this.$t("长沙"),
          2: this.$t("重庆"),
          3: this.$t("西安"),
        },
      ],
      images: [
        "img/home/en-1.jpg",
        "img/home/en-2.jpg",
        "img/home/en-3.jpg",
      ],
      xuanfeilist: [],
      peiduilist: [],
      yuepaolist: [],
    };
  },
  methods: {
    addgo(data) {
      // if (!localStorage.getItem('token')) {
      // 	this.$router.push({ path: '/Login' });
      // } else {
      // 	this.$router.push({
      // 		path: '/list?id=' + data.id + '&name=' + data.name
      // 	});
      // }
      this.$router.push({
        path: "/list?id=" + data.id + "&name=" + data.name,
      });
    },
    onConfirm(value) {
      this.$i18n.locale = value.key;
      localStorage.setItem("language", value.key);
      localStorage.setItem("lang", value.value);
      this.createList();
      this.$bus.$emit("changeLang",1)
      this.showPicker = false;
    },
    getAddress() {
      this.$http({
        method: "get",
        url: "indexlist",
      }).then((res) => {
        this.xuanfeilist = res.data;
      });
    },
    // getPeiduilist() {
    // 	this.$http({
    // 		method: 'get',
    // 		url: 'indexlistpeidui'
    // 	}).then(res => {
    // 		this.peiduilist = res.data;
    // 	});
    // },
    // getYuepaolist() {
    // 	this.$http({
    // 		method: 'get',
    // 		url: 'indexlistyuepao'
    // 	}).then(res => {
    // 		this.yuepaolist = res.data;
    // 	});
    // },
    xfts(id) {
      this.$router.push({ path: "/Detail?id=" + id });
    },
    createList() {
      this.yuepaolist = [
      {
          name: this.$t("丁先生"),
          tel: "(6016****" + ('000' + Math.floor(Math.random() * 10000)).slice(-4) + ")",
          title: this.$t(
            "聯繫約好時間叫我去她家操她，皮膚還是很緊緻"
            ),
        },
        {
          name: this.$t("朱先生"),
          tel: "(6016****" + ('000' + Math.floor(Math.random() * 10000)).slice(-4) + ")",
          title: this.$t(
            "顏值很高，服務很專業，還會熱舞..."
            ),
        },
        {
          name: this.$t("李先生"),
          tel: "(6016****" + ('000' + Math.floor(Math.random() * 10000)).slice(-4) + ")",
          title: this.$t(
            "床上滿分 可一字馬 性格超好 氵超多"
            ),
        },
        {
          name: this.$t("郭先生"),
          tel: "(6016****" + ('000' + Math.floor(Math.random() * 10000)).slice(-4) + ")",
          title: this.$t(
            "朋友介紹的，妞可以年輕漂亮，服務夠莞式。"
            ),
        },
        {
          name: this.$t("丁先生"),
          tel: "(6016****" + ('000' + Math.floor(Math.random() * 10000)).slice(-4) + ")",
          title: this.$t(
            "聯繫約好時間叫我去她家操她，皮膚還是很緊緻"
            ),
        },
        {
          name: this.$t("朱先生"),
          tel: "(6016****" + ('000' + Math.floor(Math.random() * 10000)).slice(-4) + ")",
          title: this.$t(
            "顏值很高，服務很專業，還會熱舞..."
            ),
        },
        {
          name: this.$t("李先生"),
          tel: "(6016****" + ('000' + Math.floor(Math.random() * 10000)).slice(-4) + ")",
          title: this.$t(
            "床上滿分 可一字馬 性格超好 氵超多"
            ),
        },{
          name: this.$t("郭先生"),
          tel: "(6016****" + ('000' + Math.floor(Math.random() * 10000)).slice(-4) + ")",
          title: this.$t(
            "朋友介紹的，妞可以年輕漂亮，服務夠莞式。"
            ),
        },
        {
          name: this.$t("丁先生"),
          tel: "(6016****" + ('000' + Math.floor(Math.random() * 10000)).slice(-4) + ")",
          title: this.$t(
            "聯繫約好時間叫我去她家操她，皮膚還是很緊緻"
            ),
        },
        {
          name: this.$t("朱先生"),
          tel: "(6016****" + ('000' + Math.floor(Math.random() * 10000)).slice(-4) + ")",
          title: this.$t(
            "顏值很高，服務很專業，還會熱舞..."
            ),
        },
        {
          name: this.$t("李先生"),
          tel: "(6016****" + ('000' + Math.floor(Math.random() * 10000)).slice(-4) + ")",
          title: this.$t(
            "床上滿分 可一字馬 性格超好 氵超多"
            ),
        },
        {
          name: this.$t("郭先生"),
          tel: "(6016****" + ('000' + Math.floor(Math.random() * 10000)).slice(-4) + ")",
          title: this.$t(
            "朋友介紹的，妞可以年輕漂亮，服務夠莞式。"
            ),
        },
        {
          name: this.$t("丁先生"),
          tel: "(6016****" + ('000' + Math.floor(Math.random() * 10000)).slice(-4) + ")",
          title: this.$t(
            "聯繫約好時間叫我去她家操她，皮膚還是很緊緻"
            ),
        },
        {
          name: this.$t("朱先生"),
          tel: "(6016****" + ('000' + Math.floor(Math.random() * 10000)).slice(-4) + ")",
          title: this.$t(
            "顏值很高，服務很專業，還會熱舞..."
            ),
        },
        {
          name: this.$t("李先生"),
          tel: "(6016****" + ('000' + Math.floor(Math.random() * 10000)).slice(-4) + ")",
          title: this.$t(
            "床上滿分 可一字馬 性格超好 氵超多"
            ),
        },
        {
          name: this.$t("郭先生"),
          tel: "(6016****" + ('000' + Math.floor(Math.random() * 10000)).slice(-4) + ")",
          title: this.$t(
            "朋友介紹的，妞可以年輕漂亮，服務夠莞式。"
            ),
        },{
          name: this.$t("丁先生"),
          tel: "(6016****" + ('000' + Math.floor(Math.random() * 10000)).slice(-4) + ")",
          title: this.$t(
            "聯繫約好時間叫我去她家操她，皮膚還是很緊緻"
            ),
        },
        {
          name: this.$t("朱先生"),
          tel: "(6016****" + ('000' + Math.floor(Math.random() * 10000)).slice(-4) + ")",
          title: this.$t(
            "顏值很高，服務很專業，還會熱舞..."
            ),
        },
        {
          name: this.$t("李先生"),
          tel: "(6016****" + ('000' + Math.floor(Math.random() * 10000)).slice(-4) + ")",
          title: this.$t(
            "床上滿分 可一字馬 性格超好 氵超多"
          ),
        },
        {
          name: this.$t("郭先生"),
          tel: "(6016****" + ('000' + Math.floor(Math.random() * 10000)).slice(-4) + ")",
          title: this.$t(
            "朋友介紹的，妞可以年輕漂亮，服務夠莞式。"
            ),
        },
        {
          name: this.$t("李先生"),
          tel: "(6016****" + ('000' + Math.floor(Math.random() * 10000)).slice(-4) + ")",
          title: this.$t(
            "床上滿分 可一字馬 性格超好 氵超多"
            ),
        },
        {
          name: this.$t("郭先生"),
          tel: "(6016****" + ('000' + Math.floor(Math.random() * 10000)).slice(-4) + ")",
          title: this.$t(
            "朋友介紹的，妞可以年輕漂亮，服務夠莞式。"
            ),
        },
        {
          name: this.$t("丁先生"),
          tel: "(6016****" + ('000' + Math.floor(Math.random() * 10000)).slice(-4) + ")",
          title: this.$t(
            "聯繫約好時間叫我去她家操她，皮膚還是很緊緻"
          ),
        },
        {
          name: this.$t("朱先生"),
          tel: "(6016****" + ('000' + Math.floor(Math.random() * 10000)).slice(-4) + ")",
          title: this.$t(
            this.$t(
              "進門一看，輕熟女，我喜歡少婦，穿上高跟踩上更 突顯身材。簡單洗洗開工，口的很舒服，姐口的包裹感十足"
              )
          ),
        },
        {
          name: this.$t("李先生"),
          tel: "(6016****" + ('000' + Math.floor(Math.random() * 10000)).slice(-4) + ")",
          title: this.$t(
            "很是用心，人也比較魅，比較騷，說話溫柔，騷話說起來一套一套，讓人興奮度比較高，能激起男性的性慾"
          ),
        },
        {
          name: this.$t("郭先生"),
          tel: "(6016****" + ('000' + Math.floor(Math.random() * 10000)).slice(-4) + ")",
          title: this.$t(
            "朋友介紹的，妞可以年輕漂亮，服務夠莞式。"
          ),
        },{
          name: this.$t("丁先生"),
          tel: "(6016****" + ('000' + Math.floor(Math.random() * 10000)).slice(-4) + ")",
          title: this.$t(
            "今天正好有空閒，正好去驗證一下，就約了一下。見面一看，胸不大不小，身材很好，和照片一致。"
          ),
        },
        {
          name: this.$t("朱先生"),
          tel: "(6016****" + ('000' + Math.floor(Math.random() * 10000)).slice(-4) + ")",
          title: this.$t(
            "妹子看上去很小，服務一般，身子有點胖態度還是可以的 喜歡嫩的可以去試試..."
          ),
        },
        {
          name: this.$t("李先生"),
          tel: "(6016****" + ('000' + Math.floor(Math.random() * 10000)).slice(-4) + ")",
          title: this.$t(
            "妹子長相甜美，一顆美人痣最為誘惑身材不錯，喜歡旅遊，性格溫柔開朗"
          ),
        },
        {
          name: this.$t("郭先生"),
          tel: "(6016****" + ('000' + Math.floor(Math.random() * 10000)).slice(-4) + ")",
          title: this.$t(
            "妹子比較嫩，顏值比較高，就是皮膚有點黑。妹子最近才回上海，簡單服務陪浴口做。"
          ),
        },
      ];
      this.peiduilist = [
      {
          name: this.$t("丁先生"),
          tel: "(6016****" + ('000' + Math.floor(Math.random() * 10000)).slice(-4) + ")",
          title: this.$t("通過本站已預約......"),
        },
        {
          name: this.$t("朱先生"),
          tel: "(6016****" + ('000' + Math.floor(Math.random() * 10000)).slice(-4) + ")",
          title: this.$t("正在服務中......"),
        },
        {
          name: this.$t("李先生"),
          tel: "(6016****" + ('000' + Math.floor(Math.random() * 10000)).slice(-4) + ")",
          title: this.$t("通過本站已預約......"),
        },
        {
          name: this.$t("郭先生"),
          tel: "(6016****" + ('000' + Math.floor(Math.random() * 10000)).slice(-4) + ")",
          title: this.$t("通過本站已預約......"),
        },
        {
          name: this.$t("丁先生"),
          tel: "(6016****" + ('000' + Math.floor(Math.random() * 10000)).slice(-4) + ")",
          title: this.$t("通過本站已預約......"),
        },
        {
          name: this.$t("朱先生"),
          tel: "(6016****" + ('000' + Math.floor(Math.random() * 10000)).slice(-4) + ")",
          title: this.$t("正在服務中......"),
        },
        {
          name: this.$t("李先生"),
          tel: "(6016****" + ('000' + Math.floor(Math.random() * 10000)).slice(-4) + ")",
          title: this.$t("通過本站已預約......"),
        },
        {
          name: this.$t("郭先生"),
          tel: "(6016****" + ('000' + Math.floor(Math.random() * 10000)).slice(-4) + ")",
          title: this.$t("通過本站已預約......"),
        },
        {
          name: this.$t("路先生"),
          tel: "(6016****" + ('000' + Math.floor(Math.random() * 10000)).slice(-4) + ")",
          title: this.$t("通過本站已預約......"),
        },
        {
          name: this.$t("朱先生"),
          tel: "(6016****" + ('000' + Math.floor(Math.random() * 10000)).slice(-4) + ")",
          title: this.$t("正在服務中......"),
        },
        {
          name: this.$t("李先生"),
          tel: "(6016****" + ('000' + Math.floor(Math.random() * 10000)).slice(-4) + ")",
          title: this.$t("通過本站已預約......"),
        },{
          name: this.$t("郝先生"),
          tel: "(6016****" + ('000' + Math.floor(Math.random() * 10000)).slice(-4) + ")",
          title: this.$t("通過本站已預約......"),
        },
        {
          name: this.$t("丁先生"),
          tel: "(6016****" + ('000' + Math.floor(Math.random() * 10000)).slice(-4) + ")",
          title: this.$t("通過本站已預約......"),
        },
        {
          name: this.$t("朱先生"),
          tel: "(6016****" + ('000' + Math.floor(Math.random() * 10000)).slice(-4) + ")",
          title: this.$t("正在服務中......"),
        },
        {
          name: this.$t("李先生"),
          tel: "(6016****" + ('000' + Math.floor(Math.random() * 10000)).slice(-4) + ")",
          title: this.$t("通過本站已預約......"),
        },
        {
          name: this.$t("牛先生"),
          tel: "(6016****" + ('000' + Math.floor(Math.random() * 10000)).slice(-4) + ")",
          title: this.$t("通過本站已預約......"),
        },
        {
          name: this.$t("李先生"),
          tel: "(6016****" + ('000' + Math.floor(Math.random() * 10000)).slice(-4) + ")",
          title: this.$t("通過本站已預約......"),
        },
        {
          name: this.$t("祝先生"),
          tel: "(6016****" + ('000' + Math.floor(Math.random() * 10000)).slice(-4) + ")",
          title: this.$t("通過本站已預約......"),
        },
        {
          name: this.$t("丁先生"),
          tel: "(6016****" + ('000' + Math.floor(Math.random() * 10000)).slice(-4) + ")",
          title: this.$t("通過本站已預約......"),
        },
        {
          name: this.$t("朱先生"),
          tel: "(6016****" + ('000' + Math.floor(Math.random() * 10000)).slice(-4) + ")",
          title: this.$t("正在服務中......"),
        },
        {
          name: this.$t("趙先生"),
          tel: "(6016****" + ('000' + Math.floor(Math.random() * 10000)).slice(-4) + ")",
          title: this.$t("通過本站已預約......"),
        },
        {
          name: this.$t("張先生"),
          tel: "(6016****" + ('000' + Math.floor(Math.random() * 10000)).slice(-4) + ")",
          title: this.$t("通過本站已預約......"),
        },
      ];
      this.getAddress();
    },
  },
  created() {
    this.createList();
   
    // this.getPeiduilist();
    // this.getYuepaolist();
  },
};
</script>

<style lang="less" scoped>
.page {
  position: absolute !important;
  top: 0;
  left: 0;
  right: 0;
  background-color: #16151b;
  // height: 100%;
}
.nav-bar {
  // background: linear-gradient(90deg, #7e5678, #e6c3a1);
  height: 100px;
  background-color: #16151b;
}
.van-nav-bar {
  line-height: 50px;
}
::v-deep .van-nav-bar__title {
  max-width: 60%;
  margin: 0 auto;
  font-size: 35px;
  color: #f3c7a2;
  font-weight: bold;
}
::v-deep .van-nav-bar__content {
  height: 100px;
}
.van-sidebar {
  width: 180px;
}
.my-swipe {
  border-radius: 10px;
  height: 310px;
}
.my-swipe .van-swipe-item {
  color: #fff;
  font-size: 20px;
  // line-height: 510px;
  text-align: center;
  background-color: #39a9ed;
}
.convention-hall {
  // display: flex;
  // flex-direction: column;
  // bottom: 20px;
  // background: #f2f2f5;
  // height: 100%;
}
.convention-item {
  width: 95%;
  margin: 0 auto;
  margin-bottom: 3.5625rem;
}
.banner_img {
  display: block;
  width: 100%;
  height: 100%;
}
::v-deep .van-tab {
  font-size: 30px;
  line-height: 100px;
  font-weight: bold;
  color: #fff;
}
::v-deep .van-tabs__line {
  background-color: #f3c7a2;
}
::v-deep .van-tabs--line .van-tabs__wrap {
  height: 100px;
}
::v-deep .van-tabs__wrap--scrollable .van-tab {
  padding: 0 23px;
}
.describe {
  width: 100%;
  min-height: 9, 875rem;
  border-radius: 0.625rem;
  border: 1px solid #766350;
  margin: 0.625rem 0;
  background: linear-gradient(120deg, #212121, #313131);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0.5rem;
  position: relative;
  overflow: hidden;
}
.describe > ul {
  height: 5.1875rem;
  display: flex;
  align-items: flex-start;
}
.describe > ul > li:first-child {
  width: 7.75rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  border-right: 0.1875rem solid #e6b98a;
}
.describe > ul li {
  height: 100%;
}
.describe > div {
  height: 3.875rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #e6b98a;
  // line-height: 1.5rem;
  font-size: 0.75rem;
}
.describe > ul > li:first-child > div:first-child {
  font-size: 1rem;
  font-weight: 700;
}
.describe > ul > li:first-child div {
  color: #f3c7a2;
  height: 25%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.describe > ul > li:first-child > div:nth-child(2) {
  font-size: 0.8125rem;
}
.describe > ul > li:first-child div {
  color: #f3c7a2;
  height: 25%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.describe > ul > li:nth-child(2) {
  padding: 0 0 0 0.625rem;
  width: calc(100% - 7.6rem);
  font-size: 0.625rem;
  // line-height: 1.5625rem;
  color: #fff;
}
.describe > ul li {
  height: 100%;
}
.card {
  background-color: #8a637d;
  padding: 0.625rem;
  width: 95%;
  color: white;
  font-size: 0.8125rem;
  margin: 0.625rem auto;
  border-radius: 0.375rem;
}
::v-deep .van-row--flex {
  height: 80px;
  line-height: 80px;
}
::v-deep .van-tabs__nav {
  background-color: #16151b;
}
.rig-box {
  width: 95%;
  margin: 0.625rem auto;
}
.rig-title {
  color: #0bdab0;
  font-size: 1.125rem;
}
.rig-content {
  font-size: 20px;
  // margin-top: 10px;
  color: #fff;
}
.address {
  width: 90%;
  margin: 0 auto;
}
.xuanfei {
  width: 100%;
  height: 9.75rem;
  border-radius: 0.625rem;
  border: 0.0625rem solid #766350;
  margin: 0.625rem 0 0.625rem 0;
  background: linear-gradient(120deg, #212121, #313131);
  display: flex;
  justify-content: space-between;
  padding: 0.625rem;
  position: relative;
  overflow: hidden;
}
.xuanfei .left-div {
  width: 12.9375rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.xuanfei .right-div {
  width: 7.75rem;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.xuanfei .sign-i {
  position: absolute;
  right: -40px;
  top: 10px;
  background: #ff6056;
  font-size: 15px;
  padding: 10px 45px;
  font-style: normal;
  transform: rotate(45deg);
  color: #fff;
}
.xuanfei .left-div > div:first-child {
  background: linear-gradient(90deg, #efcba7, #dcab76);
  color: #8d5825;
  height: 1.25rem;
  padding: 0 1.25rem;
  border-radius: 0.625rem;
  line-height: 1.25rem;
  text-align: center;
  font-size: 1rem;
}
.xuanfei .left-div > div:nth-child(2) {
  margin: 0.625rem 0;
  padding-left: 0.625rem;
}
.xuanfei .left-div > div:nth-child(2) ul li {
  height: 1.125rem;
  line-height: 1.125rem;
  text-align: left;
  font-size: 0.875rem;
  color: #fff;
}
.xuanfei .left-div > div:nth-child(3) ul {
  display: flex;
}
.xuanfei .left-div > div:nth-child(3) ul > li:first-child {
  margin-right: 0.625rem;
}
.xuanfei .left-div > div:nth-child(3) ul li {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.xuanfei .left-div > div:nth-child(3) ul li img {
  height: 0.8125rem;
  margin-right: 0.125rem;
}
.xuanfei .left-div > div:nth-child(3) ul li span {
  font-size: 0.8125rem;
  color: #696969;
}
.xuanfei .right-div img {
  width: 6.5rem;
  height: 6.5rem;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 50%;
}
.scroll-cmp {
  width: 100%;
  height: 12.9375rem;
  border-radius: 0.625rem;
  border: 0.0625rem solid #766350;
  margin: 0.625rem 0 6.25rem 0;
  background: linear-gradient(120deg, #212121, #313131);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0.625rem;
  position: relative;
  overflow: hidden;
}
.scroll-cmp .van-swipe-item {
  height: 1.125rem;
}
.scroll-cmp .van-swipe-item div:first-child span {
  color: #e6b98a;
  font-size: 0.9375rem;
}
.scroll-cmp .van-swipe-item div:nth-child(2) span {
  color: #fff;
  font-size: 0.7125rem;
}
</style>
