<template>
	<div class="container page">
		<van-nav-bar :title="$t('防骗指南')" class="nav-bar">
			<template #left>
				<van-icon name="arrow-left" color="#e6b98a" @click="back()" />
			</template>
		</van-nav-bar>
		<div class="scroll-div">
			<ul>
				<li><img src="/img/home/fangpian-1.0b1b930b.png" alt="" /></li>
				<li>
					<div>
						<p>
							1.{{ $t("本平台所有资源均为实名制，小姐姐与小哥哥皆是VIP会员请对双方需一定的尊重，请不要出言不逊没有素质，经发现将取消约炮资格请谨记！，重要的事情说三遍！！！") }}
						</p>
						<p>2.{{ $t("小姐姐是不会收取任何费用，只需完成平台三次打赏任务获得约炮名额即可享受同城约炮资源，开房花销需自理哦！") }}</p>
						<p>3.{{ $t("妹子已到楼下,要求付【全款才能上楼】均为骗子无疑，请立即来平台联系接待客服举报！") }}</p>
					</div>
					<img src="/img/home/fangpian-2.2b234561.png" alt="" />
				</li>
				<li>
					<img src="/img/home/fangpian-3.6f7acad2.png" alt="" />
					<div>
						<p class="warn-p">
							{{ $t("友情提醒：老师，接待员，派单员，都不会私加客户的联系方式，上班时间均为 13.00~01.00 其余时间均不办理业务，若有在下班时间他人给你发送信息请不要相信避免资金受损我方概不负责！！！") }}
						</p>
						<p>4.{{ $t("凡是要求下载其他软件的都是骗子,请立即截图来平台联系接待客服举报！") }}</p>
						<p>5.{{ $t("本平台对每一位VIP会员寻欢经历负责,遇到任何问题请及时来平台联系接待客服举报,官方核实后退还解锁消耗!") }}</p>
					</div>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {};
	},
	methods: {
		back() {
			return window.history.back();
		}
	}
};
</script>

<style lang="less" scoped>
@import '../../assets/css/base.css';
::v-deep .van-nav-bar__title {
	color: #e6b98a;
}
.scroll-div {
	width: 100%;
	height: 100%;
	overflow-y: auto;
	background-color: #fff;
}
.scroll-div ul {
	width: 95%;
	margin: 0 auto;
	height: 100%;
	color: #000;
}
.scroll-div ul li:first-child {
	height: 6.5rem;
}
.scroll-div ul li:first-child img {
	width: 90%;
	height: 6.5rem;
	-o-object-fit: contain;
	object-fit: contain;
	display: block;
	margin: 0 auto;
}
.scroll-div ul li:nth-child(2) {
	height: 15rem;
	word-break: break-all;
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	margin-bottom: 0.625rem;
}
.scroll-div ul li:nth-child(2) div {
	width: 80%;
}
.scroll-div ul p {
	color: #000;
	font-size: 0.875rem;
	margin-bottom: 0.3125rem;
	font-weight: bold;
}
.scroll-div ul li:nth-child(2) img {
	width: 5.1875rem;
	height: 16rem;
	-o-object-fit: contain;
	object-fit: contain;
}
.scroll-div ul li:nth-child(3) {
	height: 14.25rem;
	word-break: break-all;
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
}
.scroll-div ul li:nth-child(3) img {
	width: 6.5rem;
	height: 16rem;
	float: right;
	-o-object-fit: contain;
	object-fit: contain;
}
.scroll-div ul li:nth-child(3) div {
	width: 80%;
}
.scroll-div ul p.warn-p {
	color: #e6b98a;
}
</style>
