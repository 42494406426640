import { render, staticRenderFns } from "./Setsex.vue?vue&type=template&id=0b4bc211&scoped=true&"
import script from "./Setsex.vue?vue&type=script&lang=js&"
export * from "./Setsex.vue?vue&type=script&lang=js&"
import style0 from "./Setsex.vue?vue&type=style&index=0&id=0b4bc211&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0b4bc211",
  null
  
)

export default component.exports